import styled from 'styled-components/macro'

import { Dot } from 'app/Components/UI'

export const WrapperDot = styled(Dot)`
  height: 8px !important;
  margin-left: 5px;
  margin-top: -8px;
  width: 8px !important;
`

export const Wrapper = styled.div`
  button {
    font-size: 14px;
    height: 32px;
    padding: 0 16px;

    .Svg__Icon {
      margin: 0;
    }
  }

  @media screen and (max-width: 990px) {
    span {
      margin: 0;
    }

    button {
      padding: 0 10px;
    }
  }
`
