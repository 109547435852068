import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Evaluation } from '@eclass/api'

import './style.scss'
import { embedTest } from 'assets/svg'
import { QuestionWrapper } from '../../Tests/Questions/QuestionWrapper'
import { useTest } from '../../Tests/hooks/useTest'
import TestsAlerts from '../../Tests/TestsAlerts'
import { useViewDownload } from '../PDF'
import { BtnPrimary } from '@eclass/ui-kit'

type Props = {
  evaluation: Evaluation
}

const EmbeddedExercise = ({ evaluation }: Props) => {
  const { t } = useTranslation()

  const isViewDownload = useViewDownload()
  const id = `testEmbed-${evaluation.config.id || 0}`

  const { testActions, alert, testState, loading } = useTest({
    Evaluation: evaluation,
    status: {
      code: 200,
      detail: '',
      success: true,
      name: '',
    },
  })

  const [disableBtn, setDisabledBtn] = useState(true)

  useEffect(() => {
    if (testState.optionSelect === undefined) {
      setDisabledBtn(true)
    } else {
      setDisabledBtn(false)
    }
  }, [setDisabledBtn, testState.optionSelect])

  const getFields = () => {
    const container = document.getElementById(id)
    if (!container) return []
    // Busca los campos dentro del contenedor
    const fields = container.querySelectorAll(
      'input[type="radio"], select, input[type="text"], input[type="checkbox"]'
    )
    return fields
  }

  // Detecta si algún radio está deshabilitado
  useEffect(() => {
    const checkIfAnyRadioDisabled = () => {
      const fields = getFields()
      if (fields) {
        // @ts-ignore
        const isAnyFieldDisabled = Array.from(fields).some((field) => field.disabled)
        setDisabledBtn(isAnyFieldDisabled)
      }
    }

    // Verificar inicialmente al cargar el componente
    checkIfAnyRadioDisabled()

    // Crear el observer para observar cambios en los atributos de los radios
    const observer = new MutationObserver(checkIfAnyRadioDisabled)
    const fields = getFields()
    if (fields) {
      fields.forEach((field) => {
        observer.observe(field, { attributes: true, attributeFilter: ['disabled'] })
      })
    }

    // Limpiar el observer al desmontar el componente
    return () => observer.disconnect()
  }, [testState.Evaluation])

  const props = {
    setActiveQuestion: () => {},
    evaluationState: testState.Evaluation?.config.state,
    showFeedback: testState.Evaluation?.config.state === 5,
    Feedback: testState.Evaluation?.config.Feedback!,
    // @ts-ignore
    answers: testState.Evaluation?.Answers,
    disabled: testState.Evaluation?.config.state === 5 || false,
    levelState: null,
    stageState: null,
    action: testActions,
    isMobile: false,
    gridOptions: 1,
    excercises: undefined,
    stages: [],
    stageIndex: 0,
  }

  const finalizeTest = () => {
    setDisabledBtn(true)
    testActions({ type: 'FINALIZE' })
  }

  const hiddenButton = testState.Evaluation!.config.state === 5 || isViewDownload
  const title = evaluation.header.embedTitle

  return (
    <section className="testEmbed" id={id}>
      <h5>
        <img src={embedTest} alt={'exerciseIcon'} />
        {title && <span>{title}</span>}
      </h5>
      <div className="box">
        <TestsAlerts status={alert} />
        <QuestionWrapper {...props} />
        {hiddenButton ? null : (
          <BtnPrimary disabled={disableBtn} isLoading={loading} onClick={() => finalizeTest()}>
            {t('ResourceSubmit')}
          </BtnPrimary>
        )}
      </div>
    </section>
  )
}
export default EmbeddedExercise
