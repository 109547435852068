// @ts-nocheck
// @TODO corregir Tipado de RegExpMatchArray
import { Types } from './types'
import { REPLACE_REGEX } from './utils'

const REGEXS = {
  span: /<span class="latex">(.*?)<\/span>/g,
  latex: /<latex>(.*?)<\/latex>/g,
}

/**
 * @param content
 */
export const parseLatex = (content: string) => {
  try {
    let newText = content
    const spanElements = newText.match(REGEXS.span) || []
    const latexElements = newText.match(REGEXS.latex) || []
    const list: RegExpMatchArray = [...spanElements!, ...latexElements!]
    const latexEmbeds: Types.Latex[] = []
    if (Array.isArray(list)) {
      list.forEach((latex, key) => {
        const newModule = `${REPLACE_REGEX}${key}${REPLACE_REGEX}`
        newText = newText.replace(latex, newModule)
        // Elimina etiquetas HTML
        const sanitizedLatex = latex.replace(/(<([^>]+)>)/gi, '')
        // Reemplaza \dfrac por \Large\frac
        const value = sanitizedLatex.replace(/\\dfrac/g, '\\Large\\frac')
        latexEmbeds.push({
          id: key,
          inline: value.substr(0, 2) === '\\(',
          value: value.replace(/\\\(|\\\)/gi, '').replace(/\\\[|\\\]/gi, ''),
        })
      })
    }

    return {
      content: newText,
      latexes: latexEmbeds,
    }
  } catch (error) {
    return {
      content,
      latexes: [],
    }
  }
}
