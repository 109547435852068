export const BigWarning = (props) => {
  return (
    <svg width={52} height={45} fill="none" {...props}>
      <path
        fill="#FDEED6"
        d="M21.642 44.148c11.952 0 21.642-9.69 21.642-21.642S33.594.864 21.642.864C9.689.864 0 10.554 0 22.506 0 34.46 9.69 44.148 21.642 44.148Z"
      />
      <path
        fill="#FFBC27"
        d="M18.5 24.101a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM23.5 24.101a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM28.5 24.101a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      />
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeWidth={2}
        d="M43.678 13.884C40.657 8.635 34.991 5.101 28.5 5.101c-9.665 0-17.5 7.835-17.5 17.5s7.835 17.5 17.5 17.5c6.108 0 11.484-3.129 14.615-7.87"
      />
      <path
        fill="#FFBC27"
        fillRule="evenodd"
        d="M48.782 13.92a2.5 2.5 0 0 1 2.5 2.5v4.566a2.5 2.5 0 1 1-5 0V16.42a2.5 2.5 0 0 1 2.5-2.5ZM47.014 25.65a2.5 2.5 0 0 1 3.536 0l.046.047a2.5 2.5 0 1 1-3.536 3.535l-.046-.046a2.5 2.5 0 0 1 0-3.536Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
