export const UnitSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g fill="#e0eefa" className="nc-icon-wrapper">
      <path d="M30 13H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h28a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z" />
      <path
        d="M30 21H2a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2zM30 29H2a1 1 0 0 1 0-2h28a1 1 0 0 1 0 2z"
        data-color="color-2"
      />
    </g>
  </svg>
)
